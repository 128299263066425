<template>
    <div class="page">
        <div v-for="(tableDetail, index) in tableDetails" :key="index">
            <table id="table" class="pure_table" border="1">
                <tr>
                    <th class="title" colspan="20">
                        {{ salary_header }}工资单（单位：元）
                    </th>
                </tr>
                <tr class="head">
                    <th rowspan="2">姓名</th>
                    <th rowspan="2">岗位</th>
                    <th rowspan="2">出勤天数</th>
                    <th rowspan="2">加班时数</th>
                    <th colspan="6">应发</th>
                    <th colspan="6">应扣</th>
                    <th rowspan="2">
                        税点
                    </th>
                    <th rowspan="2">
                        {{
                            tableHeader.deduct_social_security ||
                            "单位须承担社保费部分"
                        }}
                    </th>
                    <th rowspan="2">
                        {{
                            tableHeader.deduct_accumulation_fund ||
                            "单位须承担住房公积金部分"
                        }}
                    </th>
                    <th rowspan="2">实收</th>
                </tr>
                <tr class="head">
                    <th>基本工资</th>
                    <th>加班工资</th>
                    <th>
                        {{ tableHeader.grant_1 || "绩效奖金" }}
                    </th>
                    <th>
                        {{ tableHeader.grant_2 || "高温津贴" }}
                    </th>
                    <th>
                        {{ tableHeader.grant_other || "其他" }}
                    </th>
                    <th>小计</th>
                    <th>
                        {{ tableHeader.deduct_1 || "社保" }}
                    </th>
                    <th>
                        {{ tableHeader.deduct_2 || "公积金" }}
                    </th>
                    <th>
                        {{ tableHeader.deduct_3 || "水电" }}
                    </th>
                    <th>
                        {{ tableHeader.deduct_4 || "借支" }}
                    </th>
                    <th>
                        {{ tableHeader.deduct_other || "其他" }}
                    </th>
                    <th>小计</th>
                </tr>
                <tr class="tr flat">
                    <td>
                        {{
                            tableDetail.staff ? tableDetail.staff.real_name : ""
                        }}
                    </td>
                    <td>
                        {{
                            tableDetail.staff
                                ? tableDetail.staff.department_data.station_name
                                : ""
                        }}
                    </td>
                    <td>{{ tableDetail.punch_day }}</td>
                    <td>{{ tableDetail.overtime }}</td>
                    <td>{{ tableDetail.base_pay }}</td>
                    <td>{{ tableDetail.overtime_pay }}</td>
                    <td>{{ tableDetail.grant_1 }}</td>
                    <td>{{ tableDetail.grant_2 }}</td>
                    <td>{{ tableDetail.grant_other }}</td>
                    <td>{{ tableDetail.grant_subtotal }}</td>
                    <td>{{ tableDetail.deduct_1 }}</td>
                    <td>{{ tableDetail.deduct_2 }}</td>
                    <td>{{ tableDetail.deduct_3 }}</td>
                    <td>{{ tableDetail.deduct_4 }}</td>
                    <td>{{ tableDetail.deduct_other }}</td>
                    <td>{{ tableDetail.deduct_subtotal }}</td>
                    <td>{{ tableDetail.tax }}</td>
                    <td>{{ tableDetail.deduct_social_security }}</td>
                    <td>{{ tableDetail.deduct_accumulation_fund }}</td>
                    <td>{{ tableDetail.actual_salary }}</td>
                </tr>
                <tr>
                    <td class="sign" colspan="20">员工签收：</td>
                </tr>
            </table>

            <table id="table" class="pure_table" border="1">
                <tr>
                    <th class="title" colspan="20">
                        {{ salary_header }}预支经济补偿金（单位：元）
                    </th>
                </tr>
                <tr class="head">
                    <th>姓名</th>
                    <th>岗位</th>
                    <th>实收</th>
                </tr>
                <tr class="flat">
                    <td>
                        {{
                            tableDetail.staff ? tableDetail.staff.real_name : ""
                        }}
                    </td>
                    <td>
                        {{
                            tableDetail.staff
                                ? tableDetail.staff.department_data.station_name
                                : ""
                        }}
                    </td>
                    <td>{{ tableDetail.pre_salary }}</td>
                </tr>
                <tr>
                    <td class="sign" colspan="3">备注：</td>
                </tr>
                <tr>
                    <td class="sign" colspan="20">员工签收：</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import printTable from "print-js";
import { mapActions } from "vuex";

export default {
    data() {
        return {
            tableHeader: {},
            tableDetails: [],
            isEdit: true,
            salary_header: "",
        };
    },
    props: ["id"],
    created() {
        this.getSalaryAuditHeader()
            .then((res) => {
                console.log("头部", res);
                this.tableHeader = res.data.data.detail;
            })
            .catch((e) => {
                console.error(e);
            });

        this.id.forEach((id) => {
            this.getSalarySplitDetail({
                id: id,
            })
                .then((res) => {
                    console.log(res);
                    this.salary_header = res.data.data.salary_header;
                    this.tableDetails.push(
                        JSON.parse(JSON.stringify(res.data.data.detail))
                    );
                })
                .catch((e) => {
                    console.error(e);
                });
        });
    },
    mounted() {
        // this.print();
    },
    methods: {
        ...mapActions("pay", ["getSalaryAuditHeader", "getSalarySplitDetail"]),

        print() {
            printTable({
                printable: "table",
                type: "html",
                maxWidth: "100%",
                targetStyles: ["*"],
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import url(./css/table.css);

.btns {
    display: flex;
    margin-top: 25px;

    > div:nth-child(1) {
        margin-right: 10px;
    }
}
</style>
